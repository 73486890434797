#invoice {
  position: fixed;
  align-items: inherit;
}

#center-signin {
  margin: 0 auto;
}

h3,
h2 {
  color: black;
  text-align: center;
}

#list-schedule {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#next {
  width: 25rem;
  margin-left: 10rem;
}

#appointment {
  width: 20%;
}

#img {
  border-style: solid;
  border-color: lightgray;
  height: 50%;
}

#img:hover {
  opacity: 0.4;
  cursor: pointer;
}

#selected-img {
  border-style: solid;
  border-color: lightblue;
  height: 50%;
}

#selected-img:hover {
  opacity: 0.4;
  cursor: pointer;
}

@media (max-width: 900px) {
  #order-details {
    display: none;
    position: fixed;
  }
  #next {
    width: 100%;
    margin-left: auto;
  }
  #appointment {
    width: 50%;
  }
  #img {
    border-style: solid;
    border-color: lightgray;
    height: auto;
    margin-bottom: 20px;
  }
  #selected-img {
    border-style: solid;
    border-color: lightblue;
    height: auto;
    margin-bottom: 20px;
  }
}
