body {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 4%;
}

#select-user-name {
  background-color: #61f113;
  border-width: 0px;
}

#book-a-service {
  background-color: blue;
  border-width: 0px;
}

#welcome-sign {
  background-color: #61f113;
  border-width: 0px;
}

@media (max-width: 700px) {
  body {
    margin-bottom: 10%;
  }
}
/*  background-color: #f5f5f5; */
