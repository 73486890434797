/* background-image: url('https://res.cloudinary.com/dfz6nmjfw/image/upload/v1631522414/main_background-Site_nidxvt.jpg'); */
#jumbotron {
  background-image: url('../../images/LandingOpt.png');
  height: 700px;
  /* parralax css */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#header-text {
  margin: auto;
  margin-top: 13%;
}

h1 {
  color: #06a5dd;
}
#welcome-text {
  font-weight: bold;
  color: white
}

h3,
h2 {
  color: #61f113;
}

p {
  color: grey;
}

#service-selector {
  width: 50%;
  margin-left: 25%;
}

#service-options {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}

#input {
  margin-right: 5px;
}

#how-we-do {
  margin: 0 auto;
}

#styled-button {
  border-radius: 50%;
}
/* color: #61f113; */

#styled-icons {
  font-size: 7em;
  color: #06a5dd;
}

#center-dots {
  margin-top: 5%;
  margin-left: -255px;
  margin-right: -255px;
  color: #06a5dd;
}

#bathroom {
  background-image: url('https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887923/Dusting%20Images/Bathroom_qsqpji.jpg');
}

#bedroom {
  background-image: url('https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887917/Dusting%20Images/Bedroom_zgatvf.png');
  height: 95%;
}

#bed-list {
  margin-top: 25%;
}

#lounge {
  background-image: url('https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887917/Dusting%20Images/Lounge_ni1fv5.jpg');
  height: 95%;
}

#bathroom-listgroup {
  background-color: rgba(255, 255, 255, 0.8);
}

#kitchen {
  background-image: url('https://res.cloudinary.com/dfz6nmjfw/image/upload/e_improve,w_300,h_600,c_thumb,g_auto/v1643887917/Dusting%20Images/Kitchen_rwfowt.jpg');
  height: 95%;
}

@media (max-width: 700px) {
  #service-selector {
    width: 100%;
    margin-left: 0.5%;
  }
  #header-text {
    width: fit-content;
    margin: auto;
    margin-top: 29.5%;
  }
  #center-dots {
    display: none;
  }
}
