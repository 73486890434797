#signup {
  width: 40rem;
}
#upload-info {
  width: 25rem;
}

@media (max-width: 700px) {
  #signup {
    width: auto;
  }
  #upload-info {
    width: auto;
  }
}
