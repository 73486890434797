#menu-button {
  background-color: #06a5dd;
  border-width: 0px;
  border-radius: 8px;
  margin: 10px 10px 10px;
  color: white;
}

#menu-signin {
  background-color: #61f113;
  border-width: 0px;
  border-radius: 8px;
  color: white;
  margin-top: 2px;
}

#menu-bg {
  background-color: rgba(97, 241, 19, 0.5);
  text-align: center;
}

#cleaner {
  background-color: orange;
  border-width: 0px;
  border-radius: 8px;
  color: white;
  margin-bottom: 10px;
}

#regular-btn {
  border-width: 1px;
  border-color: #06a5dd;
  color: #06a5dd;
  background-color: white;
  border-radius: 8px;
  text-decoration: none;
}
#regular-btn:link {
  text-decoration: none;
}

#regular-btn:visited {
  text-decoration: none;
}
#regular-btn:hover {
  background-color: #06a5dd;
  color: white;
  text-decoration: none;
}
